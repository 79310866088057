/* eslint-disable react/no-unescaped-entities */
import Aos from 'aos';
import { graphql, Link } from 'gatsby';
import window from 'global';
import * as React from 'react';
import LazyLoad from 'react-lazyload';
import Layout from '../components/layout';
import MainSubtitle from '../components/MainSubtitle';
import MainTitle from '../components/MainTitle';
import Section from '../components/Section';
import SectionContentContainer from '../components/SectionContentContainer';
import Seo from '../components/seo';
import blogBgImg from '../images/technical-blogs.webp';
import * as textStyles from '../styles/text.module.css';

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const allPosts = data.allMarkdownRemark.nodes;
  const emptyQuery = '';
  const [state, setState] = React.useState({ filteredData: [], query: emptyQuery });
  const handleInputChange = (event) => {
    const query = event.target.value;

    // return all filtered blogs
    const filteredData = allPosts.filter((post) => {
      const { description, title, tags } = post.frontmatter;
      return (
        // standardize data with .toLowerCase()
        // return true if the description, title or tags contains the query string
        description.toLowerCase().includes(query.toLowerCase()) || title.toLowerCase().includes(query.toLowerCase()) || (tags && tags.join('').toLowerCase().includes(query.toLowerCase()))
      );
    });

    // update state according to the latest query and results
    setState({
      query, // with current query string fron 'Input' event
      filteredData, // with filtered data from posts.filter(post => (//filteredData))
    });
  };
  const hasSearchResults = state.filteredData && state.query !== emptyQuery;
  const posts = hasSearchResults ? state.filteredData : allPosts;
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    Aos.init();
    Aos.refresh();
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  // if (posts.length === 0) {
  //   return (
  //     <Layout location={location} title={siteTitle}>
  //       <Seo title="All posts" />
  //       <Bio />
  //       <p>
  //         No blog posts found. Add markdown posts to "content/blog" (or the
  //         directory you specified for the "gatsby-source-filesystem" plugin in
  //         gatsby-config.js).
  //       </p>
  //     </Layout>
  //   );
  // }

  return (
    <Layout location={location} title={siteTitle}>
      <Section backgroundImage={blogBgImg} customStyle={{ textAlign: 'center' }}>
        <SectionContentContainer customStyle={{ left: 0 }}>
          {dimension.width > breakpoint ? (
            <>
              {' '}
              <MainTitle text="BLOGS" style={{ fontSize: 'calc(3vw + 3vh)' }} />
              <br />
              <MainSubtitle text="Practical guides for your curious minds" style={{ fontSize: 'calc(1.2vw + 1.2vh)' }} />
            </>
          ) : (
            <>
              {' '}
              <MainTitle text="BLOGS" />
              <br />
              <MainSubtitle text="Practical guides for your curious minds" />
            </>
          )}
        </SectionContentContainer>
      </Section>
      <Section customStyle={{ backgroundColor: 'black' }}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer customStyle={{
            position: 'relative',
            padding: '10vh 0',
            width: '50vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
          }}
          >
            <Seo title="All posts" />
            <input
              type="text"
              aria-label="Search"
              placeholder="Find out your favorite blogs"
              onChange={handleInputChange}
              style={{
                width: '100%', border: 'none', borderBottom: 'solid gray calc(0.1vh + 0.1vw)', marginBottom: '10vh', paddingBottom: '1vh', backgroundColor: 'transparent', fontSize: 'calc(0.8vh + 0.8vw)', textAlign: 'center', color: 'white',
              }}
            />
            <ol style={{ listStyle: 'none' }}>
              {posts.map((post) => {
                const title = post.frontmatter.title || post.fields.slug;
                const { thumbnail } = post.frontmatter;

                return (
                  <LazyLoad scroll once height="100%">
                    {/* <div data-aos="fade-down" data-aos-duration="500"> */}
                    <Link to={post.fields.slug} itemProp="url">
                      <li
                        key={post.fields.slug}
                        style={{
                          marginBottom: '8vh',
                        }}
                      >
                        <article
                          className="post-list-item"
                          itemScope
                          itemType="http://schema.org/Article"
                          style={{ display: 'flex' }}
                        >
                          {thumbnail !== null ? (
                            <img
                              alt="Thumbnail"
                              src={thumbnail}
                              style={{
                                marginRight: '3%', width: '18vw', height: '11.2vw',
                              }}
                            />
                          ) : ''}
                          <div>
                            <header>
                              <h2 className={textStyles.mainTitleFont} style={{ fontSize: 'calc(1.2vh + 1.2vw)' }}>
                                <span itemProp="headline">{title}</span>
                              </h2>
                              {post.frontmatter.tags.map((tag) => (
                                <small
                                  className={textStyles.mainSubtitleFont}
                                  style={{
                                    fontSize: 'calc(0.8vh + 0.8vw)', fontWeight: 'bold', color: 'white', marginRight: '1vw', padding: '0.4vh 0.4vw', backgroundColor: '#707070', borderRadius: 'calc(0.1vw + 0.1vh)', fontFamily: 'PTSans-Bold',
                                  }}
                                >
                                  {tag}
                                </small>
                              ))}
                              <br />
                              <small className={textStyles.mainSubtitleFont} style={{ fontSize: 'calc(0.7vh + 0.7vw)', color: 'gray' }}>{post.frontmatter.date}</small>
                            </header>
                            <section style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                            }}
                            >
                              <p
                                style={{
                                  fontSize: 'calc(0.8vh + 0.8vw)',
                                  lineHeight: '3.2vh',
                                }}
                                className={textStyles.mainSubtitleFont}
                                dangerouslySetInnerHTML={{
                                  __html: post.frontmatter.description || post.excerpt,
                                }}
                                itemProp="description"
                              />
                            </section>
                          </div>
                        </article>
                      </li>
                    </Link>
                    {/* </div> */}
                  </LazyLoad>
                );
              })}
            </ol>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer customStyle={{
            position: 'relative',
            padding: '10vh 2vw',
            width: '100vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
          }}
          >
            <Seo title="All posts" />
            <input
              type="text"
              aria-label="Search"
              placeholder="Find out your favorite blogs"
              onChange={handleInputChange}
              style={{
                width: '100%', border: 'none', borderBottom: 'solid gray calc(0.1vh + 0.1vw)', marginBottom: '10vh', paddingBottom: '1vh', backgroundColor: 'transparent', fontSize: 'calc(1.5vh + 1.5vw)', textAlign: 'center', color: 'white',
              }}
            />
            <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
              {posts.map((post) => {
                const title = post.frontmatter.title || post.fields.slug;
                const { thumbnail } = post.frontmatter;

                return (
                  <div data-aos="fade-down" data-aos-duration="500">
                    <Link to={post.fields.slug} itemProp="url">
                      <li
                        key={post.fields.slug}
                        style={{
                          marginBottom: '3vh',
                        }}
                      >
                        <article
                          className="post-list-item"
                          itemScope
                          itemType="http://schema.org/Article"
                          style={{ margin: '0 3%' }}
                        >
                          {thumbnail !== null ? (
                            <img
                              alt="Thumbnail"
                              src={thumbnail}
                              style={{
                                width: '100%', height: '40vw',
                              }}
                            />
                          ) : ''}
                          <div>
                            <header style={{ textAlign: 'left' }}>
                              <h2 className={textStyles.mainTitleFont} style={{ fontSize: 'calc(2vh + 2vw)' }}>
                                <span itemProp="headline">{title}</span>
                              </h2>
                              {post.frontmatter.tags.map((tag) => (
                                <small
                                  className={textStyles.mainSubtitleFont}
                                  style={{
                                    fontSize: 'calc(1.4vh + 1.4vw)', fontWeight: 'bold', color: 'white', marginRight: '2vw', padding: '1vh 2vw', backgroundColor: '#707070', borderRadius: 'calc(0.1vw + 0.1vh)', fontFamily: 'PTSans-Bold',
                                  }}
                                >
                                  {tag}
                                </small>
                              ))}
                              <br />
                              <small className={textStyles.mainSubtitleFont} style={{ fontSize: 'calc(1.5vh + 1.5vw)', color: 'gray' }}>{post.frontmatter.date}</small>
                            </header>
                          </div>
                        </article>
                      </li>
                    </Link>
                  </div>
                );
              })}
            </ol>
          </SectionContentContainer>
        ) }
      </Section>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          thumbnail
          tags
        }
      }
    }
  }
`;
